<template>
  <v-container>
    <district-list/>
  </v-container>
</template>

<script>
import DistrictList from "@/components/app_setting/district/DistrictList";

export default {
  name: "DistrictPage",
  components: {
    DistrictList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Districts'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>